<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ total_count }}</h2>
                <p class="fontsize-sm m-0">
                  Total New Accounts
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ pending_count }}</h2>
                <p class="fontsize-sm m-0">
                  Pending Accounts
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ approved_account }}</h2>
                <p class="fontsize-sm m-0">
                  Approved Accounts
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ approved_account }}</h2>
                <p class="fontsize-sm m-0">
                  Disapproved Accounts
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'CollectionDashboard',

  middleware: ['auth', 'collection'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      total_count: 0,
      pending_count: 0,
      approved_account: 0,
      disapproved_account: 0
    }
  },

  mounted () {
    core.index()
    this.fetchData()
  },

  methods: {
    async fetchData () {

    }
  }
}
</script>
